'use strict'

###*
 # @ngdoc service
 # @name mundoComponents.factory:MundoHelper

 # @description

###
angular
.module 'mundoComponents'
.factory 'MundoHelper', [
  '$log',
  '$sce',
  '$filter',
  'UserPermissions'
  'UnitsManager'
  'ContactManager'
  'DeviceManager'
  '_',
  (
    $log
    $sce
    $filter
    UserPermissions
    UnitsManager
    ContactManager
    DeviceManager
    _
  ) ->

    MundoHelper = (entityManager) ->
      {
        getVal : (row, keys, type = null) ->
          if row == "none"
            row
          else
            currentKey = keys.shift()
            currentVal = if currentKey of row then row[currentKey] else 'none'
            if keys.length == 0
              @getTypeValue(currentVal, type)
            else
              @getVal(currentVal, keys, type)

        getValue : (row, path, multiple = false, type = null) ->
          value = null
          if multiple isnt false
            value = @getVals(row, path.split('.'), multiple, type)
          else
            value = @getVal(row, path.split('.'), type)

          if entityManager.valuePostProcessor?
            value = entityManager.valuePostProcessor path, value, row

          value

        isDefined : (s) ->
          if s?
            s

        checkAction : (action) ->
          action in entityManager.conf.actions


        setColorValue : (currentVal) ->
          upperCaseColor = $filter('uppercase')(currentVal)
          colorDiv = '<div style="background-color:#' + currentVal + '" class="color-box"></div>'
          $sce.trustAsHtml(colorDiv + upperCaseColor)

        getTypeValue : (currentVal, type) ->
          switch type
            when 'dateTime' then $filter('date')(currentVal, 'dd/MM/yyyy HH:mm:ss')
            when 'date' then $filter('date')(currentVal, 'dd/MM/yyyy')
            when 'boolean' then  @setBooleanIconForValue currentVal
            when 'color' then @setColorValue currentVal
            when 'array' then @setArrayValue currentVal
            else currentVal


        setArrayValue : (currentVal) ->
          str = '<ul class="list-simple">'

          currentVal.forEach (v) ->
            str += "<li>#{v}</li>"

          str += '</ul>'
          $sce.trustAsHtml(str)


        setBooleanIconForValue : (currentVal) ->
          icon = ''
          color = ''
          switch currentVal
            when true
              icon = 'check'
              color = 'green'
            when false
              icon = 'close'
              color = 'red'
            else currentVal
          $sce.trustAsHtml('<md-icon class="material-icons" style="color:' + color + '">' + icon + '</md-icon>')

        getVals : (row, keys, flag, type = null) ->
          vals = ''
          currentKey = keys.shift()
          if currentKey is flag
            if row[currentKey]?.length > 0
              for sRow in row[currentKey]
                vals += @getVal(sRow, angular.copy(keys), type) + '<br>'
            else
              vals = 'none'
          else
            if row[currentKey]
              @getVals(row[currentKey], angular.copy(keys), flag, type)
          $sce.trustAsHtml(vals)

        hasPermission : (permission = entityManager.editPermission) ->
          if permission?
            UserPermissions.check permission
          else
            true

        getEntityManager: (type) ->
          switch type
            when 'unit' then UnitsManager
            when 'device' then DeviceManager
            when 'contact' then ContactManager

      }

    MundoHelper
]
